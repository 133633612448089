import React from "react"
//import ReactDOM from "react-dom"
//import React, { Component } from 'react';
//import logo from './logo.svg';
//import logo from './assets/logo.jpg';
import './App.css';



function Header(){
	return(
		<div>
		    
        </div>
	)
}

export default Header;
